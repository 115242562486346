// eslint-disable-next-line
'use strict'
import React from 'react'
import '../css/header.css'
import logo from '../static-contents/Target_logo_white.png'

//Renders page header
class AppHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      report: false,
    }
  }
  render() {
    return (
      <div
        style={{
          overflow: 'hidden',
          padding: '-20px',
          marginTop: '-10px',
          marginLeft: '-10px',
          marginRight: '-10px',
          height: '75px',
          display: 'block',
          fontSize: '18px',
          fontFamily: 'Helvetica,Targetica,Helvetica Neue,Helvetica',
        }}
      >
        <ul style={{ background: '#cc0000', height: '100px' }} id="nav">
          <li style={{ float: 'left', paddingTop: '6px' }}>
            <img
              src={logo}
              alt="logofile"
              style={{
                height: '45px',
                width: '45px',
                display: 'inline',
                marginLeft: '15px',
                marginTop: '8px',
              }}
            ></img>{' '}
          </li>
          <li style={{ float: 'left', paddingTop: '8px', marginLeft: '20px' }}>
            <p style={{ color: 'white', display: 'block', fontWeight: 600 }}>
              Guest Wi-Fi Authorization
            </p>
          </li>
        </ul>
      </div>
    )
  }
}

export default AppHeader
