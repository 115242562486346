// eslint-disable-next-line
'use strict'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

function Copyright() {
  return (
    <div className="row mx-0" style={{ paddingRight: '35px' }}>
      <Typography variant="body2" color="textSecondary" align="center">
        {' © '}
        Target Brands, Inc.
        <br />
        The Bullseye Design is a registered trademark of Target Brands, Inc.
        {/* {new Date().getFullYear()} */}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    padding: theme.spacing.unit,
    marginTop: 'auto',
    backgroundColor: 'white',
  },
}))

export default function StickyFooter() {
  const classes = useStyles()

  return (
    <div className="row mx-0 bg-light">
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Copyright />
        </Container>
      </footer>
    </div>
  )
}
