// eslint-disable-next-line
'use strict'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Grid,
  Typography,
  Button,
  Dialog,
  FormControl,
  FormGroup,
  Snackbar,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import MuiAlert from '@material-ui/lab/Alert'
import DialogContentText from '@material-ui/core/DialogContentText'
import { logEvent, LogLevel } from '@praxis/component-logging'
import '../css/header.css'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  createForm: {
    padding: 30,
    minWidth: 1000,
    margin: '1% 10% 3% 2%',
    marginLeft: '30px',
    fontFamily: 'Helvetica',
    fontSize: 23,
  },
  formHeaderText: {
    color: 'grey',
    marginLeft: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    fontSize: 20,
    fontWeight: 600,
  },
  formHeaderLine: {
    backgroundColor: 'grey',
    height: 3,
    marginTop: theme.spacing.unit,
  },
}))
// const history = useHistory()
const Details = (props) => {
  const location = useLocation()

  // console.log(location.pathname)
  // console.log(location.state)
  // console.log(location)
  const initialMinute = 2,
    initialSeconds = 0
  const [seconds, setSeconds] = useState(initialSeconds)
  const [minutes, setMinutes] = useState(initialMinute)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [reqId, setReqId] = useState(null)
  // const [name, setName] = useState(null)
  const [id, setID] = useState(null)
  const [email, setEmail] = useState(null)
  const [primaryhomephn, setPrimaryhomephn] = useState(null)
  const [primaryworkphn, setPrimaryworkphn] = React.useState(null)
  const [retrynum, setRetrynum] = useState(0)
  const [block, setBlock] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [dialogblock, setDialogblock] = React.useState(false)
  const [dialogopen, setDialogopen] = useState(false)
  const [dis, setDis] = React.useState(true)
  const [disCheck, setDisCheck] = React.useState(true)
  const [disAuth, setDisAuth] = React.useState(true)
  const [disCode, setDisCode] = React.useState(false)
  const [check, setCheck] = React.useState(null)
  const [otp, setOtp] = useState(null)
  const [retry, setRetry] = React.useState(false)
  const [wrongNumber, setWrongNumber] = React.useState(false)
  const [cancel, setCancel] = React.useState(false)
  const [cancelopen, setCancelopen] = React.useState(false)
  // const [mac, setMac] = useState(null)
  // const [baseGrantUrl, setBaseGrantUrl] = useState(null)
  // const [userContinueUrl, setUserContinueUrl] = useState(null)
  // const [successUrl, setSuccessUrl] = useState(null)
  const mac = location.state.mac
  const successUrl = location.state.successUrl
  const loc = location.state.loc
  const devicetype = location.state.deviceType
  const [dialogopenterms, setDialogopenterms] = useState(null)
  const [disTerms, setDisTerms] = React.useState(true)
  // const [accesible, setAccesible] = React.useState(true)
  // const [workphn, setWorkphn] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState(null)
  // const [primaryworkphone, setPrimaryworkphone] = React.useState(null)
  // const [primaryhomephone, setPrimaryhomephone] = React.useState(false)
  const [phnlabel, setPhnlabel] = React.useState(null)
  const [loading, setLoading] = React.useState(null)
  const [smsoption, setSmsoption] = React.useState(null)
  const [disresend, setDisResend] = React.useState(false)
  const env = props.env
  var telephones = []
  // const [telephones1, setTelephones] = React.useState('')
  // const [unique1, setUnique] = React.useState('')
  // var unique = []
  const [em, setEm] = React.useState('')
  // var em = new String()
  //timer counter
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          Timeout()
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  // Block access when clientmac is null and blockenv is true
  // useEffect(() => {
  //   var payload = window.location.href
  //   var url = new URL(payload)
  //   var clientMAC = url.searchParams.get('client_mac')
  //   console.log(clientMAC)
  // if (clientMAC === null && process.env.REACT_APP_ENV === 'prod') {
  //   setAccesible(false)
  // } else {
  //   setAccesible(true)
  // }
  // }, [])

  function WorkerDetails(zid) {
    setPrimaryworkphn(null)
    setPrimaryhomephn(null)
    fetch(env.workerurl + zid, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Api-Key': env.key,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        setErrorMessage('')
        if (!responseData.length) {
          console.log('caught')
          setErrorMessage('Enter a valid ID!')
        } else {
          setEmail(responseData[0].worker.email.work_email)
          if (responseData[0].worker.telephones.primary_work_phone) {
            setPrimaryworkphn(
              responseData[0].worker.telephones.primary_work_phone
            )
          }
          if (responseData[0].worker.telephones.primary_home_phone) {
            setPrimaryhomephn(
              responseData[0].worker.telephones.primary_home_phone
            )
          }
          console.log(responseData)
          console.log(responseData[0].worker.telephones)
          console.log(telephones)
        }
      })
      .catch((error) => console.warn(error))
  }

  //Posts data to db and triggers sms
  function SubmitTMData(phone) {
    logEvent({ message: mac }, { level: LogLevel.Info })
    logEvent({ message: 'Heloooooo' }, { level: LogLevel.Info })
    fetch(env.submitTMDataUrl, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Api-Key': env.key,
      },
      body: JSON.stringify({
        zid: id,
        mac_address: mac,
        status: 'INPROGRESS',
        sms_option: smsoption,
        location: loc,
        device_type: devicetype,
      }),
    })
      .then((request) => request.json())
      .then((response) => {
        console.log(response)
        if (response['message'] === 'Invalid number') {
          HandleWrongNumber()
        } else {
          requestId(response['id'])
        }
      })
      .catch((err) => console.log('HandleProceedError -> ', err))
  }

  // Sets status
  function HandleWrongNumber() {
    setWrongNumber(true)
    setDisTerms(true)
    setCancelopen(true)
  }

  //Updates OTP status in db
  function UpdateStatus(id, otp) {
    fetch(env.updateStatusUrl, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Api-Key': env.key,
      },
      body: JSON.stringify({
        id: id,
        otp: otp,
      }),
    })
      .then((request) => request.json())
      .then((response) => {
        StatusMsg(response['status'])
      })
      .catch((err) => console.log('HandleProceedError -> ', err))
  }

  // Checks if user is bloked for 30 min
  function BlockTM(sms_option) {
    fetch(env.blockTMUrl, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Api-Key': env.key,
      },
      body: JSON.stringify({
        mac_address: mac,
        sms_option: sms_option,
      }),
    })
      .then((request) => request.json())
      .then((response) => {
        console.log(response)
        setBlock(response['message'])
      })
      .catch((err) => console.log('HandleProceedError -> ', err))
  }

  function ResendOTP() {
    logEvent({ message: mac }, { level: LogLevel.Info })
    logEvent({ message: 'Heloooooo' }, { level: LogLevel.Info })
    fetch(env.resendOtpTMUrl, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Api-Key': env.key,
      },
      body: JSON.stringify({
        id: reqId,
      }),
    })
      .then((request) => request.json())
      .then((response) => {
        console.log(response)
      })
      .catch((err) => console.log('HandleProceedError -> ', err))
  }

  //Sets request ID
  function requestId(id) {
    setReqId(id)
  }

  //Sets status
  function StatusMsg(msg) {
    if (msg === 'Approved') {
      window.location.replace(successUrl)
      setOpen(true)
      dialoghandleClose()
    } else if (msg === 'Wrongotp') {
      if (retrynum + 1 < 3) {
        setRetrynum(retrynum + 1)
        setRetry(true)
      } else {
        setDisCode(true)
        setDis(true)
        setDialogblock(true)
        UpdateStatus(reqId, 'Blocked')
        // Savetime()
        setRetry(false)
      }
    }
  }

  //Extracts client mac address, base and user grant url from payload
  // function extract() {
  //   var payload = window.location.href
  //   logEvent({ message: 'payload-' + payload }, { level: LogLevel.Info })
  //   var url = new URL(payload)
  //   logEvent({ message: 'url=' + url }, { level: LogLevel.Info })
  //   var clientMAC = url.searchParams.get('client_mac')
  //   var baseUrl = url.searchParams.get('base_grant_url')
  //   var usercontinueUrl = url.searchParams.get('user_continue_url')
  //   setBaseGrantUrl(baseUrl)
  //   setMac(clientMAC)
  //   logEvent({ message: clientMAC }, { level: LogLevel.Info })
  //   if (clientMAC == null) {
  //     setMac('test_mac_addresss')
  //   }
  //   console.log(mac)
  //   console.log(baseGrantUrl)
  //   console.log(userContinueUrl)
  //   setUserContinueUrl(usercontinueUrl)
  //   setSuccessUrl(baseUrl + '?continue_url=' + usercontinueUrl)
  //   logEvent(
  //     { message: 'usercontinueUrl' + usercontinueUrl },
  //     { level: LogLevel.Info }
  //   )
  //   logEvent({ message: 'successUrl-' + successUrl }, { level: LogLevel.Info })
  // }

  //Sets OTP entered
  const handleOtp = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/gi, '')
    if (e.target.value.length === 6) {
      setOtp(e.target.value)
    }
  }

  //Set and handles phone number entered
  // const handlePhone = (e) => {
  //   console.log(email)
  //   // extract()
  //   e.target.value = e.target.value.replace(/[^0-9]/gi, '')
  //   setPhoneNumber(e.target.value)
  //   if (e.target.value.length === 10 && name != null) {
  //     setDisCheck(false)
  //     setDis(false)
  //     var PhoneNumber = '+91' + e.target.value
  //     BlockUser(PhoneNumber, mac)
  //   }
  // }

  //Set and handles name entered
  const handleID = (e) => {
    // console.log(test)
    setLoading(false)
    setDis(true)
    setPhoneNumber(null)
    setEmail(null)
    setEm(null)
    setDisAuth(true)
    logEvent({ message: 'This is the beginning' }, { level: LogLevel.Info })
    // extract()
    // setErrorMessage('Id must be 7 digits!')
    e.target.value = e.target.value.replace(/[^A-z\s0-9]/gi, '')
    if (
      (e.target.value[0] === 'Z' ||
        e.target.value[0] === 'z' ||
        e.target.value[0] === 'a' ||
        e.target.value[0] === 'A') &&
      e.target.value.length === 7
    ) {
      setErrorMessage('')
      setID(e.target.value)
      WorkerDetails(e.target.value)
    } else if (
      e.target.value[0] !== 'Z' &&
      e.target.value[0] !== 'z' &&
      e.target.value[0] !== 'a' &&
      e.target.value[0] !== 'A' &&
      e.target.value.length === 6
    ) {
      setErrorMessage('')
      setID(e.target.value)
      WorkerDetails(e.target.value)
    } else if (
      e.target.value[0] !== 'Z' &&
      e.target.value[0] !== 'z' &&
      e.target.value[0] !== 'a' &&
      e.target.value[0] !== 'A' &&
      e.target.value.length === 7
    ) {
      setErrorMessage('')
      setID(e.target.value)
      WorkerDetails(e.target.value)
    } else if (
      e.target.value[0] !== 'Z' &&
      e.target.value[0] !== 'z' &&
      e.target.value[0] !== 'a' &&
      e.target.value[0] !== 'A' &&
      e.target.value.length > 7
    ) {
      setErrorMessage('Enter a valid ID')
    } else if (e.target.value.length > 7) {
      setErrorMessage('Enter a valid ID')
    }
  }

  const keyPress1 = (e) => {
    setLoading(false)
    // if (errorMessage === '') {
    WorkerDetails(id)
    if (errorMessage !== '') {
      window.location.reload()
    } else {
      // setTimeout(() => console.log("Hello, World!"), 5000);
      // setTimeout('', 5000);
      setDisAuth(false)
      console.log('value', id)
      // WorkerDetails(id)
      // if(email){
      //   setLoading(false)
      // }
      // else setLoading(true)
      // handlephns()
      console.log(email)
      console.log(em)
      console.log(telephones)
    }
    // put the login here
  }
  // }

  const keyPress = (e) => {
    setLoading(false)
    if (e.keyCode === 13) {
      WorkerDetails(e.target.value)
      if (errorMessage !== '') {
        window.location.reload()
      } else {
        // setTimeout(() => console.log("Hello, World!"), 5000);
        // setTimeout('', 5000);
        setDisAuth(false)
        console.log('value', e.target.value)
        // WorkerDetails(e.target.value)
        // if(email){
        //   setLoading(false)
        // }
        // else setLoading(true)
        // handlephns()
        console.log(email)
        console.log(em)
        console.log(telephones)
      }
      // put the login here
    }
  }

  // const handlephns = () => {
  //   extract()
  //   if (email != null) {
  //     setEm('*****' + email.substring(email.length - 15))
  //   }
  //   if (primaryworkphn != null) {
  //     const x = primaryworkphn.replace(/[^0-9]/gi, '')
  //     setPrimaryworkphone(x)

  //     console.log(primaryworkphone)
  //     const b = x.substring(x.length - 10)
  //     var t = b.replace(/\d(?=\d{4})/g, '*')
  //     var l = { value: b, label: t }
  //     setPrimaryworkphone(b)
  //     telephones.push(l)
  //   }
  //   if (primaryhomephn != null) {
  //     const y = primaryhomephn.replace(/[^0-9]/gi, '')
  //     setPrimaryhomephone(y)

  //     console.log(primaryhomephone)
  //     var a = y.substring(y.length - 10)
  //     var s = a.replace(/\d(?=\d{4})/g, '*')
  //     var k = { value: a, label: s }
  //     // var s= a.substring(0, a.length - 4).replace(/\d/g,"*")+a.substring(6,);
  //     setPrimaryhomephone(a)
  //     telephones.push(k)
  //   }
  //   // setTelephones(telephones)
  //   unique = Array.from(new Set(telephones.map((item) => item.value))).map(
  //     (value) => {
  //       return {
  //         value: value,
  //         label: telephones.find((item) => item.value === value).label,
  //       }
  //     }
  //   )
  //   // unique = [...new Set(telephones)]
  //   setUnique(unique)
  //   console.log(unique)
  //   console.log(telephones)
  //   setTimeout(() => console.log('Hello, World!'), 5000)
  // }

  //Starts timer and makes POST call to trigger SMS
  const submitClicked = (event) => {
    console.log(block)
    if (block === true) {
      setDialogblock(true)
    } else if (block === false) {
      setMinutes(2)
      setSeconds(0)
      setDialogopenterms(true)
      setDisCheck(false)
    }
  }

  const handleTerms = () => {
    setMinutes(2)
    setSeconds(0)
    if (phoneNumber != null) {
      var inPhoneNumber = `+91${phoneNumber}`
      console.log(inPhoneNumber)
      console.log(email)
      SubmitTMData(inPhoneNumber)
    } else {
      SubmitTMData(phoneNumber)
    }
    setDialogopen(true)
    setDialogopenterms(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRetry = () => {
    setRetry(false)
  }

  const handleCancel = () => {
    setCheck(false)
    setDisTerms(true)
    setCancel(false)
  }

  const handleResend = () => {
    ResendOTP()
    setRetrynum(0)
    setMinutes(2)
    setSeconds(0)
    setDisResend(true)
  }

  //Enables Next button only if Terms and Condn is checked
  const handleCheck = (e) => {
    setDisTerms(true)
    // extract()
    setCheck(e.target.checked)
    if (e.target.checked === true) {
      setDisTerms(false)
    }
  }

  const dialoghandleClose = (e) => {
    setDialogopen(false)
    // window.location.reload()
  }

  const dialogblockClose = (e) => {
    setDialogblock(false)
    setDialogopen(false)
    window.location.reload()
  }
  //Validates OTP by making a call to backend
  const handleClick = () => {
    if (retrynum >= 2) {
      setRetrynum(retrynum + 1)
      setDisCode(true)
      setCancelopen(true)
    }
    UpdateStatus(reqId, otp)
    setOtp(null)
  }

  //Handle if request is cancelled
  const handleReject = () => {
    setRetrynum(0)
    UpdateStatus(reqId, '0')
    setCancel(true)
    dialoghandleClose()
  }

  const handleCloseTerms = () => {
    setCancel(true)
    setDialogopenterms(false)
  }

  function Timeout() {
    UpdateStatus(reqId, '')
    dialoghandleClose()
  }

  function sms_option(option) {
    setSmsoption(option)
    console.log(smsoption)
  }

  const handlePrimaryWorkPhn = (e) => {
    setSmsoption(null)
    setSmsoption('primary_work_phone')
    sms_option('primary_work_phone')
    setPhnlabel(e.target.value)
    setDis(false)
    if (!email) {
      setDis(true)
    }
    BlockTM('primary_work_phone')
  }
  const handlePrimaryHomePhn = (e) => {
    setSmsoption(null)
    setSmsoption('primary_home_phone')
    setPhnlabel(e.target.value)
    sms_option('primary_home_phone')
    setDis(false)
    if (!email) {
      setDis(true)
    }
    BlockTM('primary_home_phone')
  }
  const handleEmail = (e) => {
    setSmsoption(null)
    setSmsoption('email')
    setPhoneNumber(null)
    setDis(false)
    if (!email) {
      setDis(true)
    }
    BlockTM('email')
  }

  const classes = useStyles()

  return (
    <React.Fragment>
      <Dialog
        open={dialogblock}
        onClose={dialogblockClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Alert! '}</DialogTitle>
        <DialogContent>
          <Grid container alignItems="left" justify="center" direction="column">
            <Grid item>
              {smsoption === 'email' ? (
                <Typography>
                  You have reached the maximum number of retry attempts for the
                  phone number {phnlabel}! Please try again in 30 minutes.
                </Typography>
              ) : (
                <Typography>
                  You have reached the maximum number of retry attempts for the
                  email {phnlabel}! Please try again in 30 minutes.
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogblockClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <div
        className="row mx-0"
        style={{
          margin: '1% 5% 3% 5%',
          height: '700px',
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              height: '4.5vh',
              width: '4.5vh',
              paddingTop: '15px',
              marginLeft: '-0.5%',
            }}
          >
            <span className={classes.formHeaderText}>TM Details</span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingTop: '10px',
            }}
          >
            <Divider className={classes.formHeaderLine} />
          </Grid>
        </Grid>
        <Grid
          style={{ marginLeft: '0.3%' }}
          container
          alignItems="left"
          justify="center"
          direction="column"
        >
          <br />
          <Grid item>
            <Typography>Enter your Login ID:</Typography>
          </Grid>
          <Grid item class="inputWithButton">
            <input
              style={{
                fontFamily: 'Helvetica,Targetica,Helvetica Neue,Helvetica',
                fontSize: 15,
                color: 'black',
                height: '25px',
                borderStyle: 'solid',
                borderRadius: '4px',
                borderWidth: 0.001,
                background: 'white',
              }}
              type="text"
              placeholder="ZID/AID"
              minLength="6"
              maxLength="7"
              required
              id="standard-basic"
              onChange={handleID}
              onKeyDown={keyPress}
            ></input>
            <button onClick={keyPress1}>Next</button>
            {/* {errorMessage && (
              <div className="error" style={{ color: '#cc0000' }}>
                {' '}
                {errorMessage}{' '}
              </div>
            )} */}
            {loading === true && <CircularProgress size={24} />}
            {/* </div> */}
          </Grid>
          <br />
          <Grid container>
            {disAuth === false ? (
              <Grid item>
                <Typography>
                  Help us to verify your credentials. Please select one of the
                  following.
                </Typography>
                <RadioGroup>
                  <FormControlLabel
                    value={email}
                    onChange={handleEmail}
                    control={<Radio />}
                    label={email}
                  />
                  {primaryworkphn != null ? (
                    <FormControlLabel
                      value={primaryworkphn}
                      onChange={handlePrimaryWorkPhn}
                      control={<Radio />}
                      label={primaryworkphn}
                    />
                  ) : (
                    <Typography></Typography>
                  )}
                  {primaryhomephn != null ? (
                    <FormControlLabel
                      value={primaryhomephn}
                      onChange={handlePrimaryHomePhn}
                      control={<Radio />}
                      label={primaryhomephn}
                    />
                  ) : (
                    <Typography></Typography>
                  )}
                  {/* {primaryhomephn != null ? (
                    <FormControlLabel
                      value={primaryhomephn}
                      onChange={handleAuthMethod}
                      control={<Radio />}
                      label={primaryhomephn}
                    />
                  ) : (
                    <Typography></Typography>
                  )}
                  {primaryworkphn != null ? (
                    <FormControlLabel
                      value={primaryphn}
                      onChange={handleAuthMethod}
                      control={<Radio />}
                      label={primaryphn}
                    />
                  ) : (
                    <Typography></Typography>
                  )} */}
                </RadioGroup>
                <Typography>
                  Target will send a one-time passcode (OTP) to the selected
                  form of authentication. Message and data rates may apply for
                  the mobile number.
                </Typography>
                <br />
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={dis}
                  onClick={submitClicked}
                >
                  Next
                </Button>{' '}
              </Grid>
            ) : (
              <Typography></Typography>
            )}
          </Grid>
        </Grid>
        <Dialog
          open={dialogopenterms}
          onClose={dialoghandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" align="center">
            {'Target India Wireless Terms of Use'}
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              alignItems="left"
              justify="center"
              direction="column"
            >
              <Grid item>
                <Typography variant="body1" align="justify">
                  BY CONNECTING TO THIS WIRELESS NETWORK (the "Service"), YOU
                  ACKNOWLEDGE THAT YOU HAVE READ ALL OF THE TERMS AND CONDITIONS
                  OF THIS WIRELESS TERMS OF USE ("TERMS OF USE") BETWEEN YOU AND
                  TARGET CORPORATION INDIA ("Target"), UNDERSTAND THEM, AGREE TO
                  BE LEGALLY BOUND BY THEM AND HAVE THE LEGAL CAPACITY TO DO SO.
                  If you do not agree to these Terms of Use, do not use or
                  access the Service.
                  <br />
                  The Service is offered by Target Corporation India and is not
                  intended to subject Target to the laws or jurisdiction of any
                  state, country or territory other than that of the India. You
                  are responsible for complying with all local laws, rules and
                  regulations. Target reserves the right to charge fees in
                  connection with the Service or to suspend or discontinue the
                  Service, in whole or in part, at any time and in Target’s sole
                  discretion.
                  <br /> <br />
                  <b>Your Responsibilities</b>
                  <br />
                  Use of the Service is at your own risk. There are privacy and
                  security risks associated with connecting to the Internet over
                  wireless communications. The Service is not encrypted or
                  secured in any way, and any material or other communications
                  that you transmit or receive using the Service may be
                  accessible to third parties. While using the Service, you
                  assume full responsibility and risk for: (i) your own privacy
                  and security; (ii) implementing any safeguards you deem to be
                  appropriate to protect and secure your privacy and systems;
                  and (iii) evaluating the suitability, appropriateness or
                  legality of any informational content or other materials you
                  may encounter online
                  <br /> <br />
                  <b>Confidentiality</b>
                  <br />
                  This service is via an open network and hence any one
                  including third parties may access your data or files or
                  otherwise monitor your connection. Target may access, preserve
                  or disclose any such information for purposes of ensuring your
                  compliance with these Terms of Use and applicable law,
                  cooperating with legal authorities, and otherwise protecting
                  the rights, property and interests of Target and its guests
                  and team members. Target cannot assure that your
                  communications or activities will be private or secure during
                  your use of the Service. YOU DO NOT HAVE AN EXPECTATION OF
                  PRIVACY OR CONFIDENTIALITY WHEN USING THE SERVICE
                  <br /> <br />
                  <b>Privacy</b>
                  <br />
                  Target will collect, use, share and protect information about
                  you, except when complying to legal / regulatory enquires, in
                  connection with your use of the Service.
                  <br />
                  We automatically collect information about your use of the
                  Service, including:
                  <br />
                  <li>Your name and mobile number</li>
                  <li>
                    Session data, e.g. date and time device connected to the
                    Service, length of session
                  </li>
                  <li>Device data</li>
                  <br />
                  Ways we use the information we collect include:
                  <br />
                  <li>
                    Internal operations, e.g. enhancing the effectiveness of the
                    Service, analyzing how the Service is used and improving our
                    mobile experience
                  </li>
                  <li>
                    Legal compliance, e.g. assist law enforcement and respond to
                    legal/regulatory inquiries
                  </li>
                  <br />
                  Information may be shared with the following, during
                  transmission & in connection with accessing and the use of
                  this service and they may use and disclose any such
                  information for the purposes of i) administering the service,
                  ii) providing service functionality iii) analysis iv) ensure
                  compliance with this agreement v) adhering to applicable laws:
                  <br />
                  <li>
                    Within Target Corporation, including Target subsidiaries and
                    affiliates.
                  </li>
                  <li>
                    With service providers- companies providing support service
                    (e.g., technical support) that may need information to
                    perform their functions.
                  </li>
                  <li>
                    With third parties (generally this information is anonymous
                    and/or aggregated)
                  </li>
                  <li>
                    For legal requirements- disclosure may occur to comply with
                    law, to enforce or ensure compliance with the Terms of Use,
                    or to protect the rights, property or safety of our company,
                    our team or others.
                  </li>
                  <br />
                  How we protect information:
                  <br />
                  We maintain administrative, technical and physical safeguards
                  to protect your information
                  <br />
                  Information about Children
                  <br />
                  If you are under 18, you may use the Service only with
                  involvement of a parent or guardian, who is bound by these
                  Terms of Use and such parent or guardian will be fully
                  responsible for such child’s use of the Service including
                  financial charges and legal liability that such child may
                  incur.
                  <br /> <br />
                  <b>Prohibited use</b>
                  <br />
                  You agree not to use the Service to transmit or receive any
                  material or other communications that: (i) are unlawful,
                  malicious, fraudulent, harassing, obscene, indecent, tortious
                  or harmful to Target, its guests or any other party; (ii)
                  violate another’s intellectual property, privacy or other
                  rights (including by intercepting, collecting or harvesting
                  any material or other communications transmitted or received
                  by other users of the Service); or (iii) otherwise interfere
                  with the operation, use or enjoyment of the Service or any
                  other service, system or property. In addition, you may not
                  use the Service to transmit or receive any virus, worm, Trojan
                  horse, Easter egg, time bomb, spyware or other computer code,
                  file or program that is harmful or invasive or may or is
                  intended to damage or hijack the operation of, or to monitor
                  the use of, any hardware, software or equipment. Target has
                  the right (but not the obligation), at any time and at its
                  sole discretion, to block inappropriate material, websites or
                  other resources, and/or suspend or terminate an individual’s
                  access to the Service for engaging in these prohibited uses,
                  or for any other reason or no reason at all.
                  <br />
                  <br />
                  <b>Disclaimers</b>
                  <br />
                  THE SERVICE IS PROVIDED SOLELY AS A CONVENIENCE TO YOU, "AS
                  IS" AND "AS AVAILABLE". TARGET AND ITS AFFILIATES,
                  SHAREHOLDERS, DIRECTORS, OFFICERS, TEAM MEMBERS AND AGENTS
                  DISCLAIM ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND,
                  WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT
                  LIMITATION ANY WARRANTIES: (i) OF MERCHANTABILITY, FITNESS FOR
                  A PARTICULAR PURPOSE, OR NON-INFRINGEMENT; OR (ii) THAT ACCESS
                  TO OR USE OF THIS SERVICE OR ANY PART THEREOF WILL BE
                  UNINTERRUPTED, FREE OF VIRUSES OR OTHER HARMFUL CODE, OR
                  SECURE, OR THAT PROBLEMS WILL BE CORRECTED, EVEN IF TARGET IS
                  ON NOTICE OF SUCH PROBLEMS. YOU AGREE THAT NO ADVICE OR
                  INFORMATION GIVEN BY TARGET OR ITS EMPLOYEES SHALL CREATE ANY
                  WARRANTY. YOU AGREE THAT YOUR USE OF THIRD-PARTY WEBSITES AND
                  RESOURCES, ACCESSED THROUGH USE OF THE SERVICE IS AT YOUR OWN
                  RISK AND IS SUBJECT TO THE TERMS AND CONDITIONS OF USE
                  APPLICABLE TO THOSE THIRD-PARTY SITES AND RESOURCES
                  <br />
                  <br />
                  <b>Exclusion of liabilityy</b>
                  <br />
                  TARGET AND ITS AFFILIATES, SHAREHOLDERS, DIRECTORS, OFFICERS,
                  EMPLOYEES AND AGENTS SHALL HAVE NO LIABILITY FOR ANY DIRECT,
                  INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, PUNITIVE OR
                  EXEMPLARY DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST
                  PROFITS, LOSS OF BUSINESS, BUSINESS INTERRUPTION, LOSS OF DATA
                  OR CONFIDENTIALITY OR SECURITY, OR OTHER SIMILAR DAMAGES)
                  ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE SERVICE,
                  EVEN IF TARGET IS ADVISED OF THE POSSIBILITY OF ANY SUCH
                  DAMAGES.
                  <br />
                  <br />
                  <b>Miscellaneous</b>
                  <br />
                  Target reserves the right to make changes to these Terms of
                  Use at any time, and such changes will be effective
                  immediately upon being posted. Each time you use the Service,
                  you should review the current Terms of Use. To tell when the
                  most recent updates were made, see the date at the bottom of
                  the Terms of Use. Your continued use of the Service will
                  indicate your acceptance of the current Terms of Use; but any
                  material change to these Terms of Use will not be applied
                  retroactively to any claim or dispute between you and us in
                  connection with these Terms of Use that arose prior to the
                  applicable material change. If any provision of these Terms of
                  Use is found to be unlawful, void or for any reason
                  unenforceable, that provision will be deemed severable from
                  these Terms of Use and will not affect the validity and
                  enforceability of any remaining provision. No waiver by either
                  party of any breach or default hereunder will be deemed to be
                  a waiver of any preceding or subsequent breach or default.
                  These Terms of Use (i) will be governed by and construed in
                  accordance with the laws of India; and (ii) constitute the
                  entire agreement between you and Target regarding the accepted
                  use and terms of the Service.
                  <br />
                  <br />
                  <b>Contact Us:</b>
                  <br />
                  Mail: Target Corporation India Pvt. Ltd.
                  <br />
                  C2, Manyata Embassy Business Park (SEZ)
                  <br />
                  Nagawara Village, Outer Ring Road, Hebbal
                  <br />
                  Bangalore 560045, India
                  <br />
                  Phone: +91 80467 34000
                  <br />
                  <br />
                  last update: 08/02/2021
                  <br />
                  <br />
                </Typography>
              </Grid>
              <Grid>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      checked={check}
                      disabled={disCheck}
                      control={
                        <Checkbox color="primary" onChange={handleCheck} />
                      }
                      label="I agree"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTerms} color="primary">
              Cancel
            </Button>
            <Button disabled={disTerms} onClick={handleTerms}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogopen}
          onClose={dialoghandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick="true"
          disableEscapeKeyDown="true"
        >
          <DialogTitle id="alert-dialog-title">{'Verify Code '}</DialogTitle>
          <DialogContent>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Grid item>
                {smsoption === 'email' ? (
                  <Typography>
                    Enter the 6-digit code sent to your email {email}.
                  </Typography>
                ) : (
                  <Typography>
                    Enter the 6-digit code sent to your phone number {phnlabel}.
                  </Typography>
                )}
              </Grid>
              <Grid>
                <TextField
                  autoFocus
                  disabled={disCode}
                  id="standard-basic"
                  justify="center"
                  onInput={(e) => {
                    e.target.value = e.target.value.slice(0, 6)
                  }}
                  onChange={handleOtp}
                />
              </Grid>
            </Grid>
            {retry === true ? <h4>Wrong otp!</h4> : <h4> </h4>}
            <div>
              {(minutes === 0 && seconds === 0) || retrynum >= 3 ? (
                <h4>Code Expired!</h4>
              ) : (
                <h4>
                  {' '}
                  Code valid for {minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </h4>
              )}
            </div>
            <div>
              {retrynum < 3 ? (
                <h4>
                  {''}
                  You have {3 - retrynum} retry attempts!
                </h4>
              ) : (
                <h4>
                  You have reached the maximum number of retry attempts! Please
                  try again in 30 minutes.
                </h4>
              )}
            </div>
            <div>
              {(() => {
                if (wrongNumber) {
                  return <h4 style={{ color: 'red' }}>Invalid Phone Number</h4>
                }
              })()}
            </div>
          </DialogContent>
          <DialogActions>
            {/* <Button
              disabled={!cancelopen}
              onClick={dialoghandleClose}
              color="primary"
            >
              Ok
            </Button> */}
            <Button disabled={disresend} onClick={handleResend} color="primary">
              Resend
            </Button>
            <Button
              disabled={cancelopen}
              onClick={handleReject}
              color="primary"
            >
              Cancel
            </Button>
            <Button disabled={cancelopen} onClick={handleClick}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Successfully Authorized
          </Alert>
        </Snackbar>
        <Snackbar open={retry} autoHideDuration={5000} onClose={handleRetry}>
          <Alert onClose={handleRetry} severity="error">
            Wrong OTP
          </Alert>
        </Snackbar>
        <Snackbar open={cancel} autoHideDuration={5000} onClose={handleCancel}>
          <Alert onClose={handleCancel} severity="error">
            Request Cancelled
          </Alert>
        </Snackbar>{' '}
      </div>
      <div>
        <Dialog
          // open={!accesible}
          //TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'RESTRICTED'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Not accessible outside Target Network
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment>
  )
}

export default Details
