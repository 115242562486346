// eslint-disable-next-line
'use strict'
import '../css/header.css'
import { NavLink } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { useState, useEffect } from 'react'
// import { useEnv } from '@praxis/component-runtime-env'
//Renders page header
// class FormNavbar extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       report: false,
//     }
//   }
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing.unit,
    // marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginLeft: '1.5%',
  },
  buttonField: {
    margin: theme.spacing.unit,
    // marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    // marginLeft:'0.25%'
  },
  createForm: {
    padding: 30,
    minWidth: 1000,
    margin: '1% 10% 3% 2%',
    marginLeft: '30px',
    fontFamily: 'Helvetica',
    fontSize: 23,
  },
  formHeaderText: {
    color: 'grey',
    marginLeft: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    fontSize: 20,
    fontWeight: 600,
  },
  formHeaderLine: {
    backgroundColor: 'grey',
    height: 3,
    marginTop: theme.spacing.unit,
  },
}))
const FormNavbar = (props) => {
  const classes = useStyles()
  const [mac, setMac] = useState(null)
  const [baseGrantUrl, setBaseGrantUrl] = useState(null)
  const [userContinueUrl, setUserContinueUrl] = useState(null)
  const [successUrl, setSuccessUrl] = useState(null)
  const [loc, setLoc] = useState(null)
  const env = props.env

  const extract = () => {
    var payload = window.location.href
    logEvent({ message: 'payload-' + payload }, { level: LogLevel.Info })
    var url = new URL(payload)
    logEvent({ message: 'url=' + url }, { level: LogLevel.Info })
    var clientMAC = url.searchParams.get('client_mac')
    var baseUrl = url.searchParams.get('base_grant_url')
    var usercontinueUrl = url.searchParams.get('user_continue_url')
    var nodemac = url.searchParams.get('node_mac')
    logEvent(
      { message: 'device type- ' + window.navigator.appVersion },
      { level: LogLevel.Info }
    )
    var l
    if (nodemac === 'e0:cb:bc:06:ae:fd') {
      setLoc('TII-C2')
      l = 'TII-C2'
    } else if (nodemac === '98:18:88:f9:f8:95') {
      setLoc('TII-NXT')
      l = 'TII-NXT'
    } else {
      setLoc('local')
      l = 'local'
    }
    setBaseGrantUrl(baseUrl)
    setUserContinueUrl(usercontinueUrl)
    setSuccessUrl(baseUrl + '?continue_url=' + usercontinueUrl)
    logEvent({ message: 'mac- ' + clientMAC }, { level: LogLevel.Info })
    setMac(clientMAC)
    if (clientMAC == null) {
      setMac('test_mac_addresss')
    }
    logEvent(
      { message: 'usercontinueUrl' + usercontinueUrl },
      { level: LogLevel.Info }
    )
    logEvent({ message: 'successUrl-' + successUrl }, { level: LogLevel.Info })
    Reconnect(clientMAC, baseUrl + '?continue_url=' + usercontinueUrl, l)
  }

  function Reconnect(macaddr, successurl, location) {
    fetch(env.reconnectTMUrl, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-Api-Key': env.key,
      },
      body: JSON.stringify({
        mac_address: macaddr,
        location: location,
        device_type: window.navigator.appVersion,
      }),
    })
      .then((request) => request.json())
      .then((response) => {
        console.log(response)
        console.log(response['message'])
        if (response['message'] === true) {
          window.location.replace(successurl)
        }
      })
      .catch((err) => console.log('HandleProceedError -> ', err))
  }

  useEffect(() => {
    extract()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className="row mx-0"
      style={{
        margin: '1% 1% 1% 4%',
        overflow: 'hidden',
        paddingTop: '20px',
        marginTop: '-10px',
        marginRight: '-10px',
        display: 'block',
        fontSize: '15px',
        fontFamily: 'Helvetica,Targetica,Helvetica Neue,Helvetica',
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: '15px',
            marginLeft: '-0.5%',
          }}
        >
          <span className={classes.textField}>Select user type:</span>
        </Grid>
        <Grid
          style={{
            marginLeft: '-20px',
            marginTop: '-7px',
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              marginLeft: '7px',
            }}
          >
            <ul
              class={classes.buttonField}
              style={{
                background: 'white',
                height: '75px',
                paddingTop: '-20px',
              }}
              id="nav"
            >
              <li style={{ float: 'left', paddingTop: '6px' }}>
                <NavLink
                  to={{
                    pathname: '/tm',
                    state: {
                      mac: mac,
                      baseGrantUrl: baseGrantUrl,
                      userContinueUrl: userContinueUrl,
                      successUrl: successUrl,
                      loc: loc,
                      deviceType: window.navigator.appVersion,
                    },
                  }}
                >
                  <Button
                    style={{
                      background: '#cc0000',
                      color: 'white',
                      display: 'block',
                      fontWeight: 400,
                      width: '130px',
                      borderRadius: '5px',
                    }}
                  >
                    Team Member
                  </Button>
                </NavLink>
              </li>
              <li style={{ float: 'left', paddingTop: '6px' }}>
                <NavLink
                  to={{
                    pathname: '/guest',
                    state: {
                      mac: mac,
                      baseGrantUrl: baseGrantUrl,
                      userContinueUrl: userContinueUrl,
                      successUrl: successUrl,
                      loc: loc,
                      deviceType: window.navigator.appVersion,
                    },
                  }}
                >
                  <Button
                    style={{
                      background: '#cc0000',
                      alignItems: 'center',
                      color: 'white',
                      display: 'block',
                      fontWeight: 600,
                      width: '130px',
                    }}
                  >
                    Guest
                  </Button>
                </NavLink>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default FormNavbar
