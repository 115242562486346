// eslint-disable-next-line
'use strict'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useEnv } from '@praxis/component-runtime-env'
import AppHeader from './components/AppHeader'
import FormNavbar from './components/FormNavbar'
import Footer from './components/Footer'
import Details from './components/Details'
import GuestDetails from './components/GuestDetails'
export const App = () => {
  const env = useEnv()
  return (
    <Router>
      <AppHeader />
      <FormNavbar env={env} />
      <Switch>
        {/* <Route path="/" render={(props) => <HomePage {...props} env={env} />} /> */}
        <Route
          path="/tm"
          render={(props) => <Details {...props} env={env} />}
        />
        <Route
          path="/guest"
          render={(props) => <GuestDetails {...props} env={env} />}
        />
      </Switch>
      <Footer />
    </Router>
  )
}
export default App
